@import 'init.scss';

.header{position: absolute;z-index: 100;left: 0;top: 0;width: 100%;box-sizing:border-box;
  .header_container{position: relative;z-index: 500;max-width: 1820px;display: flex;margin: 0 auto;padding: 0 50px;align-items: center;justify-content: space-between;
    @include respond-to('d1000'){
      padding: 20px 40px;
      nav>ul,.hd_contact{display: none !important;}
    }
    @include respond-to('d1000'){
      padding: 20px;
    }
  }
  .header_logo{display: block;width: 155px;height: 60px;text-indent: -9999em;}
  .header_container{
    nav>ul{display: flex;
      >li{position: relative;width: 220px;padding: 40px 0;text-align: center;
        >ul{position: absolute;display: none;width: 100%;padding: 45px 0 20px;text-align: center;background-color: #35ADE3;
          >li{position: relative;padding: 10px 0;color: #fff;
            @media (hover: hover){
              &:hover::after{content: '';position: absolute;bottom: 5px;left: calc(50% - 47px);display: block;width: 94px;height: 1px;margin: 0 auto;background-color: #fff;}
            }
          }
        }
        @media (hover: hover){
        &:hover{background-color: #35ADE3;
            >ul{display: block;}
          }
        }
        @include respond-to('d1520'){
          width: 160px;
        }
      }
    }
  }
  
  .hd_contact{display: flex;
    &::before{content: '';display: inline-block;width: 30px;height: 30px;margin-right: 10px;margin-top: 8px;background-image: url('../img/hd_tel_b.svg');background-repeat: no-repeat;}
    span{display: block;color: $pc1;}
  }
  
  .menu_btn{display: none;
    span{display: block;width: 24px;height: 2px;margin-top: 9px;background-color: #fff;}
    &.view{
      span{width: 32px;
        &:nth-child(1){transform: rotate(-45deg);}
        &:nth-child(2){width: 0;}
        &:nth-child(3){margin-top: -12px; transform: rotate(45deg);}
      }
    }
    @include respond-to('d1000'){
      display: block;
    }
  }

  &.blue_logo{
    .header_logo{background-image: url('../img/logo_b.svg');background-repeat: no-repeat;}
    .header_container{
      nav>ul>li{
        >ul>li{color: #fff;}
        @media (hover: hover){
          &:hover{
            >a{color: #fff;}
          }
        }
      }
    }
    
    .menu_btn{
      span{background-color: #000;}
    }
  }

  &.white_logo{
    .header_logo{background-image: url('../img/logo_w.svg');background-repeat: no-repeat;}
    .header_container{
      nav>ul>li{color: #fff;
        >ul>li{color: #fff;}
      }
    }
    .hd_contact{
      &::before{background-image: url('../img/hd_tel_w.svg');}
      span{color: #fff;}
    }
    .menu_btn{
      span{background-color: #fff;}
    }
  }
  &.m_open{
    .header_logo{background-image: url('../img/logo_w.svg') !important;}
    .menu_btn{
      span{background-color: #fff !important;}
    }
  }
  @media (hover: hover){
    &:hover{background-color: #fff;
      .header_logo{background-image: url('../img/logo_b.svg');background-repeat: no-repeat;}
      .header_container{
        nav>ul>li{color: #000;
          >ul>li{color: #fff;}
          @media (hover: hover){
            &:hover{
              >a{color: #fff;}
            }
          }
        }
      }
      .hd_contact{
        &::before{background-image: url('../img/hd_tel_b.svg');transition: all 0.5s;-webkit-transition: all 0.5s;}
        span{color: $pc1;transition: all 0.5s;-webkit-transition: all 0.5s;}
      }
      .menu_btn{
        span{background-color: #000;}
      }
    }
  }
  .mobile_menu{overflow-y: auto;display: flex;position: fixed;top:0;left: 0;width: 100%;height: 100%;background-color: #35ADE3;opacity: 0;transform: translateY(-100%);transition: opacity 0.3s ease, transform 0.3s ease;
    &.view{opacity: 1;transform: translateY(0);}
    nav{width: 100%;padding: 130px 0;text-align: center;
      >ul{
        >li{padding: 20px 0;font-size: 24px;color: #fff;text-align: center;font-family: 'S-CoreDream-6Bold';
          ul{display: none;margin-top: 20px;
            li{padding-top: 20px;color: #fff;font-size: 18px;
              &:first-child{padding-top: 0;}
            }
            &.db{display: block;}
          }
        }
      }
    }
  }
}

.tos_page{
  p{color: #666;}
}

.page_top_padding{padding-top: 220px;
  @include respond-to('d594'){
    padding-top: 150px;
  }
}
.top_bg{display: flex;height: 546px;justify-content: center;background-repeat: no-repeat;background-position: 50% 0;
  h2{padding-top: 250px;color: #fff;}
  &.whypage,&.process,&.membership,&.products{background-image: url('../img/topbg_service.jpg');}
  &.coupleReview{background-image: url('../img/topbg_review.jpg');}
  &.partyReview{background-image: url('../img/topbg_party.png');}
  &.consult{background-image: url('../img/topbg_request.jpg');}
  &.contact{background-image: url('../img/topbg_contact.jpg');}
  @include respond-to('d594'){
    &.whypage,&.process,&.membership,&.products{background-image: url('../img/topbg_service_m.png');}
    &.coupleReview{background-image: url('../img/topbg_review_m.png');}
    &.partyReview{background-image: url('../img/topbg_party_m.png');}
    &.consult{background-image: url('../img/topbg_request_m.png');}
    &.contact{background-image: url('../img/topbg_contact_m.png');}
  }
}

.center_txt_cont{position: relative;padding: 150px 0;text-align: center;
  h3{
    span{display: block;
      &.point{display: inline-block;padding: 0 20px;margin: 20px 0 40px;background-color: #35ADE3;color: #fff;border-radius: 60px;}
    }
  }
  p{color: #333;
    span{color: #166EE2;}
  }
  &::after{content: '';display: block;position: absolute;left: calc(50% - 30px); bottom: -30px;z-index: 10;width: 60px;height: 60px;border: 1px solid #000;border-radius: 30px;background-image: url('../img/why_part_ico.svg');background-repeat: no-repeat;background-position: 50%;background-color: #fff;}
  @include respond-to('d594'){
    padding: 75px 0;
  }
}


.request_inp_box{padding: 60px;border: 1px solid #ddd;border-radius: 10px;
  .inp_line{display: flex;justify-content: space-between;
    .inp_item{width: calc(50% - 20px);
      .date_inp_w{display: flex;justify-content: space-between;
        .inp_date{width: 486px;}
        .inp_time{width: 129px;border-radius: 5px;}
      }
      .tel_inp_w{display: flex;justify-content: space-between;
        .inp{width: 33%;}
      }
      @include respond-to('d960'){
        .inp_item{width: calc(50% - 5px);}
      }

      @include respond-to('d960'){
        width: 100%;
        &:nth-child(2){padding-top: 40px;}
      }
      @include respond-to('d594'){
        &:nth-child(2){padding-top: 20px;}
      }
    }
    @include respond-to('d960'){
      display: block;
    }
  }
  .check_label{margin-left: 5px;
    input{display: block;position: absolute;width: 0;height: 0;overflow: hidden;opacity: 0;}
    .c{display: inline-block;padding: 20px;border: 1px solid #ddd;border-radius: 5px;color: #333;
      &::before{content: '';display: inline-block;width: 16px;height: 12px;margin-right: 10px;background-image: url('../img/check_ico.svg');}
    }
    input:checked ~ .c{
      background-color: #35ADE3;color: #fff;
      &::before{background-image: url('../img/check_ico_a.svg');}
    }
    &:first-child{margin-left: 0;}
  }
  .inp{width: 100%;height: 60px;border: 1px solid #ddd;border-radius: 5px;}
  .inp_line_block{display: block;
    .t{position: relative;
      .c1{color: #166EE2;}
      button{position: absolute;right: 0;
        &::after{content: '';display: block;
          position: absolute;
          left: calc(50% - 30px);
          bottom: -20px;
          z-index: 10;
          width: 20px;
          height: 20px;
          background-image: url('../img/why_part_ico.svg');
          background-repeat: no-repeat;
          background-position: 50%;
          background-color: #fff;
      }
      }
    }
    .region_area{
      label{display: inline-block;margin-left: 0;margin-bottom: 5px;margin-right: 5px;}
    }
    .agree_txt{overflow-y: scroll;height: 200px;padding: 30px;border: 1px solid #ddd;border-radius: 5px;background-color: #fafafa;
      pre{color: #666;}
    }
  }
  .agree_check{padding: 20px 0;border-bottom: 1px solid #eee;
    input{display: block;position: absolute;width: 0;height: 0;overflow: hidden;opacity: 0;}
    i{display: inline-block;width: 30px;height: 30px;margin-right: 10px;vertical-align: middle;border-radius: 5px;border: 1px solid #ddd;}
    i::after{content: "";display: block;width: 100%;height: 100%;background-color: transparent;transition: all 0.5s;-webkit-transition: all 0.5s;transform: scale(1,1);-webkit-transform: scale(1,1);transform: scale(1,1);}
    input:checked ~ i::after{background-color: #35ADE3;transform: scale(0.5,0.5) rotate(0.3deg);-webkit-transform: scale(0.5,0.5) rotate(0.3deg);transform: scale(0.5,0.5) rotate(0.3deg);}
    input:disabled ~ i{background-color: #eee;}
  }
  .btn_pos{position: fixed;bottom: 20px;left: 0;width: 100%;
    .sbm_btn{
      button{display: block;width: 245px;height: 60px;margin: 0 auto;text-align: center;color: #fff;background-color: #35ADE3;border-radius: 40px;
        &:disabled{background-color: #ddd;cursor: auto;}
      }
      .sbm{display: block;width: 245px;height: 60px;margin: 0 auto;text-align: center;color: #fff;background-color: #ddd;border-radius: 40px;line-height: 60px;
        &::after{content: '신청하기';}
      }
      
    }
    @include respond-to('d594'){
      bottom:0;
      .sbm_btn{
        button,.sbm{width: 100%;border-radius: 0;}
      }
      
    }
  }
  
  @include respond-to('d960'){
    padding: 20px;
  }
}


.map_wrap{border-radius: 10px;border: 1px solid #ddd;
  .map{width: 100%;height: 600px;}
  .addr_txt{display: flex;justify-content: space-between;padding: 60px;
    p{display: flex;width: 50%;
      span{display: inline-block;width: 100px;}
    }
    @include respond-to('d960'){
      display: block;padding: 30px;
      p{width: 100%;margin-top: 20px;}
    }
  }
}
.bottom_contact{display: flex;justify-content: space-between;
  .item{display: flex;justify-content: space-between;
    i{display: flex;align-items: center;justify-content: center;width: 140px;height: 140px;margin-right: 40px;border-radius: 70px;border: 1px solid #eee;background-color: #FAFAFA;
      &::before{content: '';display: block;width: 60px;height: 60px;}

      @include respond-to('d594'){
        width: 70px;height: 70px;margin: 0 auto;
        &::before{width: 30px;height: 30px;background-size: cover;}
      }
    }
    &.tel{
      i::before{background-image: url('../img/contact_ico_tel.svg');}
    }
    &.email{
      i::before{background-image: url('../img/contact_ico_email.svg');}
    }
    .txt{width: calc(100% - 140px);
      table{
        tr{
          th{width: 110px;padding: 7px 0;text-align: left;color: #166EE2;}
          td{padding: 7px 0;}
        }
      }
      @include respond-to('d960'){
        width: calc(100% - 140px);
      }
    }
    @include respond-to('d960'){
      justify-content: start;margin-top: 50px;
      &:first-child{margin-top: 0;}
    }
    @include respond-to('d594'){
      display: block;
    }
  }
  @include respond-to('d960'){
    display: block;
    
  }
}

.list_wrap{display: flex;flex-wrap: wrap;align-items: flex-start;gap: 220px 70px;background-image: url('../img/review_lst_bg.jpg');background-repeat: no-repeat;background-position: 50% 0;
  .item{width: 420px;padding: 40px;border-radius: 20px;border: 1px solid #eee;background-color: #fff;
    .img{overflow: hidden;margin-bottom: 20px;border-radius: 20px;
      img{width: 100%;height: 100%;transition: transform 0.3s;object-fit: cover;}
    }
    .txt{
      .t1{padding-bottom: 25px;}
    }
    &:nth-child(3n+2) {
      margin-top: 120px;
    }
    @include respond-to('d1440'){
      width: calc(33.333% - 20px);padding: 30px;
    }
    @include respond-to('d1000'){
      width: calc(50% - 20px);
      &:nth-child(3n+2) {
        margin-top: 0;
      }
    }
    @include respond-to('d594'){
      width: 100%;padding: 15px;
    }
    @media (hover: hover){
      &:hover{
        .img{
          img{transform: scale(1.2);}
        }
      }
    }
  }
  @include respond-to('d1440'){
    justify-content: space-between;gap: 220px 0;
  }
  @include respond-to('d1000'){
    gap: 180px 0;
  }
  @include respond-to('d594'){
    gap: 50px 0;
  }


}

.pagination{display: flex;justify-content: center;
  a{display: flex;justify-content: center;align-items: center;width: 34px;height: 34px;border-radius: 34px;color: #999;
    &.a{background-color: #35ADE3;color: #fff;}
  }
}


.view_top{
  .point{justify-content: center;}
}
.view_cont{padding-bottom: 300px;border-bottom: 1px solid #ddd;
  figure{width: auto !important;height: auto !important;max-width: 100%;
    img{width: auto !important;height: auto !important;}
  }
  h1{font-size: 2em;font-family: 'S-CoreDream-6Bold';}
  h2{font-size: 1.5em;font-family: 'S-CoreDream-6Bold';}
  h3{font-size: 1.17em;font-family: 'S-CoreDream-6Bold';}
  h4{font-family: 'S-CoreDream-6Bold';}
  h5{font-size: 0.83em;font-family: 'S-CoreDream-6Bold';}
  ol,ul{padding-inline-start: 40px;}
  li{list-style: inherit;}
  
  @include respond-to('d960'){
    padding-bottom: 150px;
    
  }
}
.view_nav{
  table{border-top: 1px solid #ddd;
    tr{border-bottom: 1px solid #ddd;
      th{width: 116px;padding: 30px 0;
        @include respond-to('d960'){
          width: 80px;
        }
      }
      td{padding: 30px 0;}
      @include respond-to('d594'){
        th,td{padding: 15px 0;}
      }
    }
  }
}
.view_list_btn{padding: 40px 0;
  a{display: block;width: 180px;padding: 20px;margin: 0 auto;border: 1px solid #707070;text-align: center;border-radius: 40px;}
  @include respond-to('d594'){
    padding: 20px 0;
  }
}

.product_lst{display: flex;justify-content: space-between;
  .item{overflow: hidden;width: calc(50% - 10px);border-radius: 20px;border: 1px solid #eee;background-color: #fff;
    .t1{text-align: center;}
    .sale_price{position: relative;background-image: url('../img/sale_price.svg');background-repeat: no-repeat;background-position: calc(50% - 75px) 15px;text-align:center;
      .price{display: inline-block;}
      .sale_percent{display: inline-block;margin-left: 25px;padding: 5px 10px;background-color: #35ADE3;border-radius: 30px;}

      &.none{background-image: none;
        .sale_percent{margin-left: -9999em;}
      }
    }
    @include respond-to('d1000'){
      width: calc(50% - 10px);
    }
    @include respond-to('d720'){
      width: 100%;margin-bottom: 20px;
    }
  }
  &.subscribe{
    .t1{padding: 10px 0;color: #fff;}
    .item{
      .bottom_cont{padding: 40px 30px;}
      &:nth-child(1){
        .t1{background-color: #aaa;}
      }
      &:nth-child(2){
        .t1{background-color: #166EE2;}
      }
      &:nth-child(3){
        .t1{background-color: #166EE2;}
      }
    }
  }
  &.joinFee{
    .item{padding: 40px 30px;
      .t1{color: #999;}
    }
  }
  .sameday_per{color: #166EE2;}
  @include respond-to('d720'){
    display: block;
  }
}

.membership_lst{padding: 120px 0;background-color: #F9F9F9;
  .product_lst{justify-content:center;}
}

.process_lst{display: flex;flex-wrap: wrap;align-items: flex-start;gap: 70px 60px;align-items: stretch;
  .item{overflow: hidden;width: 420px;border: 1px solid #eee;border-radius: 20px;background-color: #fff;
    .img{
      img{width: 100%;}
    }
    .step{padding: 10px;background-color: #35ADE3;text-align: center;color: #fff;}
    .txt{padding: 30px;
      .t1{padding-bottom: 20px;}
      .t2{padding-bottom: 20px;color: #333;}
      a{display: block;width: 100%;margin-top: 10px;padding: 20px 0;border: 1px solid #707070;border-radius: 40px;text-align: center;}
    }
    &.wait{background-color: #FAFAFA;
      .step{background-color: #061C3A;}
    }
  }
  @include respond-to('d1440'){
    gap: 70px 0;justify-content: space-between;
    .item{width: calc(33.333% - 10px);}
  }
  @include respond-to('d1000'){
    .item{width: calc(50% - 10px);}
  }
  @include respond-to('d594'){
    .item{width: 100%;
      .txt{padding: 15px;}
    }
  }
}


.error_page{padding: 220px 0 120px;
  a{display: block;width: 245px;padding: 20px;margin: 0 auto;background-color: #35ADE3;border-radius: 40px;color: #fff;}
}



// 메인페이지 공통
.main_slide{max-width: 1820px;margin: 0 auto;}

.main_wrap{
  .main_type{margin-bottom: 15px;font-size: 24px;color: #166EE2;
    &.cf{color: #fff;}
  }
  .main_tit{font-size: 50px;color: #000;
    &.cf{color: #fff;}
  }
  .main_desc{margin-top: 30px;font-size: 18px;}
  .main_more{display: flex;align-items: center;justify-content: space-between;width: 245px;height: 60px;margin-top: 80px;padding: 0 30px;border-radius: 30px;text-align: left;font-size: 18px;color: #fff;background-color: #35ADE3;
    &::after{content: '';transition: transform .3s;display: block;width: 15px;height: 15px;background-image: url(../img/main_more.svg);}
    &.w_type{border: 2px solid #fff;color: #fff;background-color: transparent;}
    &.ma{justify-content: center;margin-right: auto;margin-left: auto;text-align: center;
      &::after{display: none;}
    }
  }
  .main_intro,.main_review{padding: 120px 0;}
}
@media (hover: hover){
  .main_wrap{
    .main_more{
      &:hover{
        &::after{transform: translateX(5px);}
      }
    }
  }
}
@include respond-to('d326'){
  .main_wrap{
    .main_type{margin-bottom: 10px;font-size: 18px;}
    .main_tit{font-size: 30px;}
    .main_desc{margin-top: 15px;font-size: 14px;}
    .main_more{width: 180px;height: 50px;margin-top: 40px;padding: 0 20px;border-radius: 25px;font-size: 14px;}
    .main_intro,.main_review{padding: 60px 0;}
  }
}

//main_slide
.main_wrap{
  .main_slide{position: relative;
    .swiper{overflow: hidden;
      .swiper-slide{
        .bg{position: relative;display: block;overflow:hidden;
          >img{width: 100%;transform: scale(1.2);transition: transform 2s;opacity: 0;}
        }
        &.swiper-slide-active{
          .bg{
            >img{transform: scale(1);opacity: 1;}
          }
        }
      }
    }
    .main_pagi{z-index: 5;position: absolute;bottom: 40px;left: 50%;transform: translateX(-50%);display: flex;gap: 10px;justify-content: center;
      .swiper-pagination-bullet{opacity: 1;position: relative;width: 20px;height: 20px;margin: 0;border-radius: 0;background-color: transparent;
        svg{display: none;width: 100%;height: 100%;stroke-linecap: round;stroke-width: 20;fill: none;transform: rotate(90deg) scaleX(-1);
          circle{stroke: #fff;stroke-dasharray: 312;transform-origin: center;}
        }
        &::after{content: '';position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);width: 6px;height: 6px;border-radius: 50%;background-color: #fff;}
        &.swiper-pagination-bullet-active{
          svg{display: block;
            circle{animation: rotation 5s linear;}
          }
          &::after{display: none;}
        }
      }
    }
  }
}
@keyframes rotation {
  0% {stroke-dashoffset: 312;}
  50% {stroke-dashoffset: 152;}
  100% {stroke-dashoffset: 0;}
}
@-webkit-keyframes rotation {
  0% {stroke-dashoffset: 312;}
  50% {stroke-dashoffset: 152;}
  100% {stroke-dashoffset: 0;}
}
@include respond-to('d594'){
  .main_wrap{
    .main_slide{
      .main_mask{
        .item{
          .bg{
            &::after{height: 20%;}
          }
        }
      }
    }
  }
}
@include respond-to('d326'){
  .main_wrap{
    .main_slide{
      .main_pagi{display: none;}
    }
  }
}

//main_intro
.main_wrap{
  .main_intro{
    .main_type,.main_tit{text-align: center;}
    .inner{display: flex;align-items: start;gap: 100px;margin-top: 100px;
      .img{position: relative;width: 100%;
        figcaption{position: absolute;right: 0;bottom: 0;display: block;font-size: 18px;text-align: right;
          .name{margin-top: 5px;font-size: 24px;color: #000;}
        }
      }
      .txt{flex-shrink: 0;width: 660px;
        .t1{padding-right: 60px;font-size: 18px;}
        .vdo{overflow: hidden;height: 372px;margin: 40px 0 20px; border-radius: 20px;
          iframe{width: 100%;height: 100%;}
          @include respond-to('d1440'){
            position: relative;
            height: 0;
            padding-top: 56.25%;
            iframe{position: absolute;top: 0;left: 0;}
          }
        }
      }
    }
  }
}
@include respond-to('d1440'){
  .main_wrap{
    .main_intro{
      .inner{flex-direction: column;align-items: center;gap: 80px;
        .img{max-width: 640px;}
        .txt{width: 100%;
          .t1{padding-right: 0;}
        }
      }
    }
  }
}
@include respond-to('d326'){
  .main_wrap{
    .main_intro{
      .inner{gap: 40px;margin-top: 50px;
        .img{
          figcaption{position: static;font-size: 14px;
            .name{font-size: 18px;}
          }
        }
        .txt{
          .t1{font-size: 14px;}
          .vdo{border-radius: 10px;}
        }
      }
    }
  }
}

//part_wrap
.main_wrap{
  .part_wrap{
    &.part_zero{background-color: transparent;}
  }
}

//main_review
.main_wrap{
  .main_review{overflow: hidden;position: relative;display: flex;gap: 100px;padding-left: calc(50% - 700px);
    &::before{content: '';z-index: -1;position: absolute;bottom: 0;left: 0;width: 810px;height: 736px;background-image: url('../img/main_review_bg.png');background-size: cover;}
    .tit_cont{flex-shrink: 0;width: 460px;padding-top: 100px;}
    .desc_cont{width: 100%;min-width: 0;}
    .main_review_slide{
      .swiper{overflow: hidden;
        .swiper-slide{overflow: hidden;height: unset;border-radius: 20px;background-color: #fff;
          >a{display: flex;flex-direction: column;width: 100%;height: 100%;}
          .img{flex-shrink: 0;overflow: hidden;aspect-ratio: 1/1;
            >img{transition: transform .3s;object-fit: cover;width: 100%;height: 100%;}
          }
          .txt{height: 100%;padding: 25px 30px;border: 1px solid #eee;border-top: 0;border-radius: 0 0 20px 20px;
            .point{margin-bottom: 20px;}
            .t1{font-size: 20px;color: #000;}
            .t2{margin-top: 20px;font-size: 16px;color: #707070;}
          }
        }
      }
      .slide_control{display: flex;gap: 40px;align-items: center;margin-top: 30px;padding-right: calc(50vw - 700px);
        .arrow_btns{flex-shrink: 0;display: flex;gap: 5px;
          .btn{display: block;width: 60px;height: 60px;border: 2px solid #eee;border-radius: 50%;background-image: url('../img/main_review_arrow.svg');background-repeat: no-repeat;background-position: center;
            &.next{transform: scaleX(-1);}
          }
        }
        .progress{position: relative;width: 100%;height: 4px;border-radius: 2px;background-color: #eee;
          .swiper-pagination-progressbar-fill{height: 4px;border-radius: 2px;background-color: #999;}
        }
      }
      @include respond-to('d1000'){
        padding: 0 20px;
      }
    }
  }
}
@media (hover: hover){
  .main_wrap{
    .main_review{
      .main_review_slide{
        .swiper{
          .swiper-slide{
            &:hover{
              .img{
                >img{transform: scale(1.2);}
              }
              .txt{
                .t1{color: #166EE2;}
              }
            }
          }
        }
        .slide_control{
          .arrow_btns{
            .btn{
              &:hover{border: 2px solid #35ADE3;background-color: #35ADE3;background-image: url('../img/main_review_arrow_w.svg');}
            }
          }
        }
      }
    }
  }
}
@include respond-to('d1440'){
  .main_wrap{
    .main_review{flex-direction: column;gap: 80px;padding-left: 0;
      &::before{width: 100%;height: 654px;}
      .tit_cont{display: flex;flex-direction: column;align-items: center;width: 90%;margin: 0 auto;padding-top: 0;text-align: center;}
      .main_review_slide{
        .swiper{padding: 0 5%;}
        .slide_control{padding: 0 5%;}
      }
    }
  }
}
@include respond-to('d326'){
  .main_wrap{
    .main_review{gap: 40px;
      &::before{height: 290px;}
      .main_review_slide{
        .swiper{
          .swiper-slide{
            .txt{padding: 20px;
              .point{margin-bottom: 10px;}
              .t1{font-size: 16px;}
              .t2{margin-top: 10px;font-size: 12px;}
            }
          }
        }
        .slide_control{margin-top: 20px;
          .arrow_btns{display: none;}
        }
      }
    }
  }
}


.part_wrap{padding: 120px 0;background-size: cover;background-position: center;background-attachment: fixed;
  .part_type{margin-bottom: 15px;font-size: 24px;font-weight: bold;color: #166EE2;
    &.cf{color: #fff;}
  }
  .part_tit{font-size: 50px;font-weight: bold;color: #000;line-height: 150%;
    &.cf{color: #fff;}
  }
  .part_more{display: flex;align-items: center;justify-content: space-between;width: 245px;height: 60px;margin-top: 80px;padding: 0 30px;border-radius: 30px;text-align: left;font-size: 18px;font-weight: bold;color: #fff;background-color: #35ADE3;
    &::after{content: '';transition: transform .3s;display: block;width: 15px;height: 15px;background-image: url('../img/part_more.svg');}
  }
  .inner{position: relative;display: flex;align-items: start;gap: 100px;
    .tit_cont{position: sticky;top: 0;left: 0;width: 100%;padding-top: 170px;}
    .desc_cont{flex-shrink: 0;width: 820px;}
    &.reverse_type{flex-direction: row-reverse;}
    &.column_type{flex-direction: column;
      .tit_cont{position: static;display: flex;flex-direction: column;align-items: center;padding-top: 0;text-align: center;}
      .desc_cont{width: 100%;}
    }
  }
  .it_inner{display: flex;
    .img_cont{width: 50%;
      .img{width: 100%;height: 100%;
        >img{object-fit: cover;width: 100%;height: 100%;}
      }
    }
    .txt_cont{display: flex;flex-direction: column;justify-content: center;width: 50%;padding: 150px calc(50vw - 700px) 150px 120px;}
  }
  .photo_inner{display: flex;gap: 80px;align-items: start;
    .img_cont{display: flex;gap: 20px;width: calc(50% - 20px);
      .img{overflow: hidden;width: calc(50% - 10px);border-radius: 20px;
        >img{width: 100%;}
      }
    }
    .txt_cont{width: calc(50% - 60px);}
  }

  .comp_box{
    .line{display: flex;align-items: start;
      &+.line{margin-top: 40px;}
      .box{flex-shrink: 0;overflow: hidden;width: calc(50% + 20px);border-radius: 20px;background-color: #fff;box-shadow: 0 3px 20px rgba(0,0,0,.1);
        .img{overflow: hidden;
          >img{width: 100%;}
        }
        .type{padding: 10px 20px;font-size: 20px;font-weight: bold;color: #fff;text-align: center;background-color: #aaa;}
        .in_bdrs{overflow: hidden;border-radius: 20px;}
        .txt{padding: 30px 50px 30px 30px;border: 1px solid #eee;border-top: 0;border-radius: 0 0 20px 20px;
          .t1{margin-bottom: 20px;font-size: 24px;font-weight: bold;color: #000;}
          .t2{font-size: 18px;font-weight: bold;color: #061C3A;}
          .t3{margin-top: 10px;font-size: 18px;}
        }
        &.blue_type{position: relative;margin: 40px 0 0 -40px;
          .type{background-color: #35ADE3;}
          .txt{padding: 30px;
            .t2{color: #166EE2;}
          }
          &::after{content: '';position: absolute;top: 0;left: 0;box-sizing: border-box;width: 100%;height: 100%;border: 4px solid #35ADE3;border-radius: inherit;}
        }
      }
    }
    &.grid_type{
      .line{gap: 80px;align-items: stretch;
        .box{flex-shrink: 1;width: calc(50% - 40px);padding: 40px;border: 1px solid #eee;box-shadow: none;
          .txt{margin-top: 40px;padding: 0;border: 0;text-align: center;}
          &.blue_type{margin: 0;
            &::after{display: none;}
          }
        }
      }
    }
  }
  .ico_txt_lst{
    >ul{
      >li{display: flex;gap: 40px;
        &+li{margin-top: 60px;padding-top: 60px;background-image: url('../img/part_ico_txt_line.png');background-repeat: repeat-x;background-position: left top;}
        .ico{flex-shrink: 0;display: block;width: 140px;height: 140px;border: 1px solid #eee;border-radius: 50%;background-color: #fafafa;background-repeat: no-repeat;background-position: center;
          &1_1{background-image: url('../img/part_ico1_1.svg');}
          &1_2{background-image: url('../img/part_ico1_2.svg');}
        }
        .txt{width: 100%;}
      }
    }
  }
  .price_box{display: flex;flex-wrap: wrap;margin-top: 45px;
    .box{width: 50%;padding: 40px 60px;
      &.box_l{border: 1px solid #eee;border-radius: 20px 0 0 0;border-bottom: 0;}
      &.box_r{border: 1px solid #eee;border-radius: 0 20px 0 0;border-bottom: 0;border-left: 0;}
      &.box_b{width: 100%;border-radius: 0 0 20px 20px;background-color: #35ADE3;}
      >dl{display: flex;gap: 20px;align-items: center;width: 100%;
        dt{width: calc(50% - 10px);}
        dd{display: flex;align-items: center;justify-content: flex-end;gap: 20px;width: calc(50% + 10px);min-height: 74px;text-align: right;
          .p_line{font-size: inherit;}
          .price{color: #166EE2;}
          .percent{flex-shrink: 0;display: flex;align-items: center;justify-content: center;width: 74px;height: 74px;border-radius: 50%;color: #fff;text-align: center;background-color: #35ADE3;
            >em{font-weight: bold;}
          }
        }
      }
    }
  }
  &.part01{background-image: url('../img/part01_bg.jpg');}
  &.part02{background-image: url('../img/part02_bg.jpg');}
  &.part03{background-image: url('../img/part03_bg.jpg');}
  &.why_part04{background-image: url('../img/why_part04_bg.jpg');}
  &.part_zero{background-color: #F4FBFE;}
  &.part_cost{background-image: url('../img/part_cost_bg.jpg');}
  &.part_party{padding: 0;border-top: 1px solid #ddd;background-color: #F9F9F9;background-image: url('../img/part_party_bg.png');background-repeat: no-repeat;background-size: auto;background-position: right bottom;background-attachment: unset;}
  &.part_photo{border-top: 1px solid #ddd;background: none;}
}
@media (hover: hover){
  .part_wrap{
    .part_more{
      &:hover{
        &::after{transform: translateX(5px);}
      }
    }
  }
}

@include respond-to('d1440'){
  .part_wrap{
    .inner{flex-direction: column;gap: 80px;
      .tit_cont{position: static;display: flex;flex-direction: column;align-items: center;padding-top: 0;text-align: center;}
      .desc_cont{width: 100%;}
      &.reverse_type{flex-direction: column;}
    }
    .it_inner{display: block;
      .img_cont{width: 100%;}
      .txt_cont{display: block;width: 100%;padding: 120px 5%;}
    }
    .photo_inner{flex-direction: column;align-items: center;gap: 60px;max-width: 680px;margin-right: auto;margin-left: auto;
      .img_cont{width: 100%;}
      .txt_cont{width: 100%;}
    }
    .comp_box{max-width: 820px;margin: 0 auto;
      &.grid_type{
        .line{flex-direction: column;gap: 40px;
          .box{width: 100%;}
        }
      }
    }
    .price_box{margin-top: 80px;
      .box{
        >dl{flex-direction: column;
          dt{width: 100%;}
          dd{justify-content: flex-start;width: 100%;text-align: left;}
        }
      }
    }
    &.part03{background-image: url('../img/part03_bg_m.jpg');background-position: center bottom;}
  }
}
@include respond-to('d326'){
  .part_wrap{padding: 60px 0;
    .part_type{margin-bottom: 10px;font-size: 18px;}
    .part_tit{font-size: 30px;}
    .part_more{width: 180px;height: 50px;margin-top: 40px;padding: 0 20px;border-radius: 25px;font-size: 13px;}
    .inner{gap: 40px;}
    .it_inner{
      .txt_cont{padding: 60px 5%;}
    }
    .photo_inner{gap: 30px;
      .img_cont{gap: 10px;
        .img{width: calc(50% - 5px);border-radius: 10px;}
      }
    }
    .comp_box{max-width: 320px;
      .line{flex-direction: column;
        &+.line{margin-top: 20px;}
        .box{width: 100%;
          .type{font-size: 16px;}
          .txt{padding: 20px;
            .t1{margin-bottom: 10px;font-size: 18px;}
            .t2{font-size: 13px;}
            .t3{margin-top: 5px;font-size: 13px;}
          }
          &.blue_type{margin: -20px 0 0;
            .txt{padding: 20px;}
          }
        }
      }
      &.grid_type{
        .line{gap: 20px;
          .box{padding: 20px;
            .txt{margin-top: 20px;padding: 0;}
          }
        }
      }
    }
    .ico_txt_lst{
      >ul{
        >li{gap: 20px;
          &+li{margin-top: 30px;padding-top: 30px;}
          .ico{width: 90px;height: 90px;background-size: 40px auto;}
          .txt{width: 100%;}
        }
      }
    }
    .price_box{margin-top: 40px;
      .box{width: 100%;padding: 20px 30px;
        &.box_l{border-radius: 10px 10px 0 0;}
        &.box_r{border: 1px solid #eee;border-bottom: 0;border-radius: 0;}
        &.box_b{border-radius: 0 0 10px 10px;}
        >dl{gap: 10px;
          dd{gap: 10px;min-height: auto;
            .percent{width: 54px;height: 54px;}
          }
        }
      }
    }
    &.part_cost{background-position: center right;}
    &.part_party{background-position: center bottom;background-size: contain;}
  }
}

.footer{background-color: #04142A;
  .f_logo{display: flex;justify-content: center;padding: 80px 0 50px;
    @include respond-to('d594'){
      padding: 40px 0 20px;
    }
  }
  .f_nav{display: flex;justify-content: center;flex-wrap: wrap;gap: 0 40px;color: #fff;
    @include respond-to('d594'){
      justify-content: space-between;gap: 0;
      a{width: 50%;margin: 10px 0; text-align: center;}
    }
  }
  .f_info{color: #999;
    .dib{display: inline-block;margin: 0 15px;line-height: 150%;
      b{font-weight: 300;color: #fff;font-family: 'S-CoreDream-6Bold';}
    }
    @include respond-to('d594'){
      padding: 0;
    }
    
  }
  .f_copyright{color: #999;
    @include respond-to('d594'){
      padding-top: 25px;
    }
  }
  @include respond-to('d594'){
    &.req_footer{display: none;}
  }
}

.floating_link{display: none;position: fixed;right: 10px;bottom: 10px;
  .request{display: flex;align-items: center;justify-content: center;width: 50px;height: 50px;border-radius: 25px;background-color: #166EE2;color: #fff;line-height: 130%;}
  @include respond-to('d1000'){
    display: block;
  }
}

.popup{position: fixed;z-index: 5000;left: 0;top: 0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;padding: 20px;
  .popup_wrap{position: relative;z-index: 5000;overflow: hidden;width: 720px;max-width: 100%;margin: 50px auto 0;border-radius: 20px;}
  .popup_cont{padding: 60px 40px;background-color: #fff;}
  .popup_ctrl{display: flex;justify-content: space-between;padding: 15px 40px;background-color: #35ADE3;
    button{color: #fff;
      img{vertical-align: middle;margin-bottom: 3px;}
    }
  }
  @include respond-to('d594'){
    position: absolute;display: block;
  }
  &::after{content: "";position: fixed;z-index: 3000;display: block;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);}
}

.alert{position: fixed;z-index: 5000;left: 0;top: 0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;padding: 20px;
  .popup_wrap{position: relative;z-index: 5000;overflow: hidden;width: 720px;max-width: 100%;margin: 50px auto 0;border-radius: 20px;}
  .popup_cont{padding: 60px 40px;background-color: #fff;
    p{white-space: pre-line;}
  }
  .popup_ctrl{display: flex;padding: 15px 40px;background-color: #35ADE3;
    button{display: block;width: 100%;color: #fff;text-align: center;}
  }
  /*
  @include respond-to('d594'){
    position: absolute;display: block;
  }
  */
  &::after{content: "";position: fixed;z-index: 3000;display: block;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);}
}

.result_page{padding: 220px 0 290px; background-image: url('../img/result_bg.jpg');background-position: 50% 0;background-repeat: no-repeat;background-size:cover;
  a{display: block;width: 245px;margin: 0 auto;padding: 20px;background-color: #35ADE3;color: #fff;text-align: center;border-radius: 40px;}
}

.request_page.pb120{
  @include respond-to('d594'){
    padding-bottom: 240px;
  }
}